<template>
  <div
    id="sx_drawer_history"
    class="bg-white"
    data-sx-drawer="true"
    data-sx-drawer-name="history"
    data-sx-drawer-activate="true"
    data-sx-drawer-overlay="true"
    data-sx-drawer-width="{default:'300px', 'md': '500px'}"
    data-sx-drawer-direction="end"
    data-sx-drawer-toggle="#sx_drawer_history_toggle"
    data-sx-drawer-close="#sx_drawer_history_close"
  >
    <div class="card w-100 border-0" id="sx_drawer_history">
      <div
        class="card-header bg-primary p-2 border-0 rounded-0"
        id="sx_drawer_history_header"
      >
        <div class="card-title mt-0 mb-0 p-3">
          <i class="fas fa-history text-white float-start fs-4 me-2"></i>
          <div
            class="d-flex text-white justify-content-center flex-column me-3"
          >
            {{
              $t(
                "BaseModelFields.History",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </div>
          <template
            v-if="!$isMobile() && historyLoaded && histories.length > 0"
          >
            <span class="btn btn-info cursor-default me-2">{{
              $t(
                "BaseModelFields.TotalRecordFormat",
                {},
                { locale: this.$store.state.activeLang }
              ).replaceAll("COUNT", histories.length)
            }}</span>
            <router-link
              type="button"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              :title="
                $t(
                  'BaseModelFields.LargerView',
                  {},
                  { locale: this.$store.state.activeLang }
                )
              "
              class="btn btn-warning btn-icon"
              :to="{
                name: 'history',
                params: {
                  key: this.$route.params.key,
                  id: this.$route.params.id,
                },
              }"
            >
              <i class="fas fa-external-link-alt"></i>
            </router-link>
          </template>
        </div>
        <div class="card-toolbar mt-0 mb-0 p-3">
          <i
            class="fas fa-times fs-4 text-white cursor-pointer"
            id="sx_drawer_history_close"
          ></i>
        </div>
      </div>
      <div class="card-body sx-drawer-body" id="sx_drawer_history_body">
        <template v-if="$isMobile() && historyLoaded && histories.length > 0">
          <div class="text-center mb-2">
            <span class="btn btn-info me-2 cursor-default">{{
              $t(
                "BaseModelFields.TotalRecordFormat",
                {},
                { locale: this.$store.state.activeLang }
              ).replaceAll("COUNT", histories.length)
            }}</span>
            <router-link
              type="button"
              class="btn btn-warning"
              :to="{
                name: 'history',
                params: {
                  key: this.$route.params.key,
                  id: this.$route.params.id,
                },
              }"
            >
              <span>
                <i class="far fa-window-maximize"></i>
                {{
                  $t(
                    "BaseModelFields.LargerView",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</span
              >
            </router-link>
          </div>
        </template>
        <SetLoader v-if="!historyLoaded" />
        <ul v-else class="sx_tmtimeline">
          <li v-for="history in histories" :key="history.publicId">
            <time class="sx_tmtime"
              ><span>{{ history.createdAt }}</span> <span></span
            ></time>
            <div
              class="sx_tmicon"
              :class="{
                'bg-success':
                  history.logType == 'FieldServiceCreateRecord' ||
                  history.logType == 'FieldServiceBulkImport' ||
                  history.logType == 'FieldServiceExportRecords',
                'bg-primary':
                  history.logType == 'FieldServiceUpdateRecord' ||
                  history.logType == 'FieldServiceUpdateFieldValue' ||
                  history.logType == 'KanbanViewServiceUpdateFieldValue' ||
                  history.logType == 'CalendarViewServiceUpdateFieldValue',
                'bg-danger': history.logType == 'FieldServiceDeleteRecord',
                'bg-warning':
                  history.logType == 'FieldServiceGetRecord' ||
                  history.logType == 'FieldServiceBulkUpdateRecords',
              }"
            >
              <i
                class="fas fa far"
                :class="{
                  'fa-plus': history.logType == 'FieldServiceCreateRecord',
                  'fa-user':
                    history.logType == 'FieldServiceUpdateRecord' ||
                    history.logType == 'FieldServiceUpdateFieldValue',
                  'fa-trash': history.logType == 'FieldServiceDeleteRecord',
                  'fa-kanban':
                    history.logType == 'KanbanViewServiceUpdateFieldValue',
                  'fa-calendar':
                    history.logType == 'CalendarViewServiceUpdateFieldValue',
                  'fa-eye': history.logType == 'FieldServiceGetRecord',
                  'fa-download': history.logType == 'FieldServiceExportRecords',
                  'fa-cogs': history.logType == 'FieldServiceBulkUpdateRecords',
                  'fa-file-import': history.logType == 'FieldServiceBulkImport',
                }"
              ></i>
            </div>
            <div class="sx_tmlabel empty">
              <h2>
                <a>{{ history.userName }}</a>
              </h2>
              <span
                class="fw-bolder"
                :class="{
                  'text-success':
                    history.logType == 'FieldServiceCreateRecord' ||
                    history.logType == 'FieldServiceBulkImport' ||
                    history.logType == 'FieldServiceExportRecords',
                  'text-primary':
                    history.logType == 'FieldServiceUpdateRecord' ||
                    history.logType == 'FieldServiceUpdateFieldValue' ||
                    history.logType == 'KanbanViewServiceUpdateFieldValue' ||
                    history.logType == 'CalendarViewServiceUpdateFieldValue',
                  'text-danger': history.logType == 'FieldServiceDeleteRecord',
                  'text-warning':
                    history.logType == 'FieldServiceGetRecord' ||
                    history.logType == 'FieldServiceBulkUpdateRecords',
                }"
                >{{
                  $t(
                    "History." + history.logType,
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</span
              >
              <p v-if="history.requestFrom" class="mt-2 mb-0 text-danger">
                {{
                  $t(
                    "History.Source",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}:
                {{ history.requestFrom }}
              </p>
            </div>
          </li>
        </ul>
        <template v-if="historyLoaded">
          <div class="alert alert-danger mb-0" v-if="responseError.length > 0">
            {{ this.responseError[0] }}
          </div>
          <div
            class="alert alert-warning mb-0"
            v-else-if="histories.length == 0"
          >
            {{
              $t(
                "BaseModelFields.NoRecordFound",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import "@/assets/css/history-timeline.css";
export default {
  name: "HistoryNoFooter",
  data() {
    return {
      histories: [],
      historyLoaded: false,
      responseError: [],
      actionName: "rws-GetRecordLogsOfARecord",
    };
  },
  props: [
    "customObjectKey",
    "recordPublicId",
    "logType",
    "startDate",
    "endDate",
  ],
  watch: {
    "$store.state.historyPanelShow"() {
      if (this.$store.state.historyPanelShow) {
        this.getHistory();
      }
    },
  },
  methods: {
    getHistory() {
      this.historyLoaded = false;
      this.responseError = [];
      this.$appAxios
        .get(
          `${this.actionName}?customObjectKey=${this.customObjectKey}&recordPublicId=${this.recordPublicId}`
        )
        .then((response) => {
          this.historyLoaded = true;
          var result = response.data;
          if (result.isOk) {
            this.histories = result.items;
          } else {
            this.responseError.push(result.msg);
          }
        })
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });
    },
  },
};
</script>
