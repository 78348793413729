<template>
  <SetLoader v-if="!detailPageRequestFinish && !responseError.length" />
  <AlertBox type="warning" :messages="responseError" />
  <template v-if="detailPageRequestFinish && !responseError.length">
    <HiddenFields crudType="Detail" :pageData="pageData" />
    <DesignItem
      v-for="element in designedItems"
      :key="element.id"
      :element="element"
      :items="designedItems"
    />
    <DeleteModal
      :deleteRecordId="$route.params.id"
      :customObjectKey="$route.params.key"
      :relationalRecordDeleteCustomObjectNames="
        pageData && pageData.relationalRecordDeleteCustomObjectNames
      "
      @deleteAfterCallMethod="gotoListPage()"
    />
  </template>
  <History
    :customObjectKey="$route.params.key"
    :recordPublicId="$route.params.id"
  />
  <ErrorModal
    :id="uniqueId"
    :informations="informations"
    :businessRuleResponse="businessRuleResponse"
  />
</template>
<script>
import ErrorModal from "@/components/custom/set-pages/InlineErrorModal.vue";
import $ from "jquery";
import HiddenFields from "@/components/set-design/form-items/HiddenFields.vue";
import DesignItem from "@/components/set-design/DesignItem.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { reinitializeComponents } from "@/core/plugins/themes";
import History from "@/layout/extras/HistoryNoFooter.vue";
import { showModal } from "@/core/helpers/dom";
export default {
  name: "DETAIL",
  props: ["key", "id", "pageLayoutId"],
  data() {
    return {
      pageData: {},
      designedItems: [],
      responseError: [],
      detailPageRequestFinish: false,
      uniqueId: String.newGuid(),
      setPagesCRUDResult: {},
      businessRuleResponse: {},
      informations: [],
    };
  },
  components: {
    DesignItem,
    History,
    HiddenFields,
    ErrorModal,
  },
  watch: {
    "$route.params.key"() {
      if (this.$route.name === "detail" && this.detailPageRequestFinish) {
        this.getDetailPage();
      }
    },
    "$route.params.id"() {
      if (this.$route.name === "detail" && this.detailPageRequestFinish) {
        this.getDetailPage();
      }
    },
    "$route.query.pageLayoutId"() {
      if (this.$route.name === "detail" && this.detailPageRequestFinish) {
        this.getDetailPage();
      }
    },
  },
  mounted() {
    this.getDetailPage();
    this.$store.commit("setHistoryPanelVisibility", false);

    this.setPagesCRUDResult = this.$store.getters.getSetPagesCRUDResult;
    if (this.setPagesCRUDResult) {
      this.businessRuleResponse = this.setPagesCRUDResult.businessRuleResponse;
      this.informations = this.setPagesCRUDResult.validations.filter(function (
        f
      ) {
        return f.validationType.value === 3;
      });

      this.$store.commit("setPagesCRUDResult", null);

      if (
        (this.businessRuleResponse.businessRuleResponses.length > 0 &&
          this.businessRuleResponse.notificationType != "Tooltip") ||
        this.informations.length > 0
      )
        showModal(document.getElementById(`modalInlineError_${this.uniqueId}`));
    }
  },
  created() {
    reinitializeComponents();
  },
  methods: {
    getDetailPage() {
      setCurrentPageBreadcrumbs("", [{ name: "HELP" }]);
      this.designedItems = [];
      this.responseError = [];
      this.detailPageRequestFinish = false;
      this.$root.getSetScripts(this.$route.params.key, "Detail").then(() => {
        this.pageProcess();
      });
    },
    pageProcess() {
      var key = this.$route.params.key,
        recordPublicId = this.$route.params.id,
        pageLayoutId = this.$route.query.pageLayoutId;

      if (
        String.isNullOrWhiteSpace(key) ||
        String.isNullOrWhiteSpace(recordPublicId) ||
        (!String.isNullOrWhiteSpace(recordPublicId) &&
          !String.isGuid(recordPublicId))
      ) {
        this.$router.push(this.$appHomeUrl);
        return;
      }

      var detailPageRequestUrl = `rws-SetController-Detail?key=${key}&id=${recordPublicId}`;
      if (!String.isNullOrWhiteSpace(pageLayoutId)) {
        detailPageRequestUrl += `&pageLayoutId=${pageLayoutId}`;
      }

      this.$appAxios
        .get(detailPageRequestUrl)
        .then((response) => {
          this.pageData = response.data;
          this.detailPageRequestFinish = true;
          this.$store.commit("setPageDto", this.pageData);

          if (this.pageData.isOk) {
            if (
              String.isNullOrWhiteSpace(this.pageData.layout) ||
              String.isNullOrWhiteSpace(this.pageData.layout.layout)
            ) {
              this.$store.commit("setPageLayoutAvailable", false);
              this.$router.push({
                name: "PageLayoutNone",
                params: { key: this.$route.params.key },
              });
              return;
            }

            if (this.pageData.layout.layout) {
              this.$root.operationAfterPageLoadForSetScripts();
              this.$store.commit("setPageLayoutAvailable", true);
              this.designedItems = JSON.parse(this.pageData.layout.layout);
            }

            var customObjectName = this.pageData.name,
              customObjectPluralName = this.pageData.customObjectPluralName;
            if (this.$store.state.isMultiLanguage) {
              var localizationValue =
                this.$store.getters.getLocalizationValuesByParameters({
                  itemFormulaName: this.pageData.layout.customObjectFormulaName,
                  itemTypeId: 1,
                });
              if (localizationValue) {
                customObjectName = localizationValue.value;
              }

              var localizationValueForPluralName =
                this.$store.getters.getLocalizationValuesByParameters({
                  itemFormulaName: this.pageData.layout.customObjectFormulaName,
                  itemTypeId: 11,
                });
              if (localizationValueForPluralName) {
                customObjectPluralName = localizationValueForPluralName.value;
              }
            }

            var title = this.$t(
              "Set.DetailFormat",
              {},
              { locale: this.$store.state.activeLang }
            ).replaceAll("OBJECT_NAME", customObjectName);

            this.$root.changeDocumentTitle(title);
            setCurrentPageBreadcrumbs(title, [
              {
                name: customObjectPluralName,
                isRouteLink: true,
                html: `/set/list/${this.$route.params.key}`,
              },
              {
                name: title,
              },
            ]);
          } else {
            this.$root
              .validationProcess(this.pageData.validations)
              .then((redirected) => {
                if (!redirected) {
                  this.$root.changeDocumentTitle(
                    this.$t("Components.LostWave.Title"),
                    {},
                    { locale: this.$store.state.activeLang }
                  );
                  setCurrentPageBreadcrumbs(
                    this.$t(
                      "Components.LostWave.Title",
                      {},
                      { locale: this.$store.state.activeLang }
                    ),
                    []
                  );
                  this.responseError.push(response.data.msg);
                }
              });
          }
        })
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });
    },
    gotoListPage() {
      this.$router.push({
        name: "list",
        params: {
          key: this.$route.params.key,
        },
      });
    },
  },
};
</script>
